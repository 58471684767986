<template>
  <v-card id="cw-confirmation-dialog--dialog__PEP">
    <v-card-title id="cw-confirmation-dialog__title" class="text-h5">
      <translate>
        Politically influential person?
      </translate>
    </v-card-title>
    <v-card-text id="cw-confirmation-dialog__text">
      <p v-translate>
        A politically influential person (PEP) is a person who has been in public office during the
        past year as: Head of State, Minister, Member of Parliament, Member of the governing bodies
        of political parties, Member of the Supreme Judicial Body, member of the supreme
        decision-making body of the State Treasury, director of the international community, deputy
        director and board member, Deputy Governor and Board Member, Central Bank Executive Board,
        Ambassador or Attorney-General, at least General Staff, in management positions in a wholly
        state-owned company.
      </p>
      <p v-translate>
        Close political relatives (spouses, parents, children and their spouses) and close
        associates of such persons are also politically influential.
      </p>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn
        id="cw-confirmation-dialog__confirm"
        :loading="$wait.waiting('confirmation_dialog__confirm_processing')"
        :disabled="$wait.waiting('confirmation_dialog__confirm_processing')"
        color="primary"
        text
        @click="
          $emit('dialog-cancel');
          $eventLogger.clickEvent($event);
        "
      >
        <translate>
          Close
        </translate>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'CwDialogPep',
};
</script>
